<template>
  <div class="randomRouteDataAnalysis">
    <div class="randomRouteDataAnalysis-search">
      <div class="randomRouteDataAnalysis-search-1">
        发送名称:
        <a-input
          v-model="activityCode"
          placeholder="请输入发送名称"
          allowClear
        />
      </div>
    </div>
    <div class="randomRouteDataAnalysis-search-button">
      <a-button type="primary" @click="inquire()">查 询</a-button>
      <i style="display: inline-block; margin-left: 20px"></i>
      <a-button type="primary" @click="synchronization()">同步</a-button>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="tableData"
      :pagination="false"
      :loading="loadingTable"
      size="small"
    >
    </a-table>
  </div>
</template>
<script>
export default {
  name: "randomRouteDataAnalysis",
  data() {
    return {
      columns: [
        {
          title: "发送日期",
          ellipsis: true,
          dataIndex: "sendTime",
          width: 150,
        },
        {
          title: "发送名称",
          ellipsis: true,
          dataIndex: "activityCode",
          width: 150,
        },
        {
          title: "平台账号名称",
          ellipsis: true,
          dataIndex: "channelAccountName",
          width: 200,
        },
        {
          title: "总数",
          ellipsis: true,
          dataIndex: "count",
          width: 100,
        },
        {
          title: "成功数",
          ellipsis: true,
          dataIndex: "successCount",
          width: 100,
        },
        {
          title: "成功率",
          ellipsis: true,
          dataIndex: "successCountPer",
          width: 100,
        },
        {
          title: "点击数",
          ellipsis: true,
          dataIndex: "clickCount",
          width: 100,
        },
        {
          title: "点击率",
          ellipsis: true,
          dataIndex: "clickCountPer",
          width: 100,
        },
      ],
      tableData: [],
      pagination: {},
      loadingTable: false,
      activityCode: "",
    };
  },
  mounted() {
    this.inquire();
  },
  methods: {
    synchronization() {
      this.$http.get("/sms/randomRouteDataAnalysisSyn").then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.inquire();
        }
      });
    },
    inquire() {
      let data = {
        activityCode: this.activityCode,
      };
      this.loadingTable = true;
      this.$http
        .get("/sms/randomRouteDataAnalysis", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableData = res.data;
            this.tableData.forEach((item) => {
              this.$set(item, "sendTime", item.sendTime.slice(0, 10));
            });
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.randomRouteDataAnalysis {
  .randomRouteDataAnalysis-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .randomRouteDataAnalysis-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .randomRouteDataAnalysis-search-button {
    margin-bottom: 20px;
  }
}
</style>
