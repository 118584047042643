import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import antd from "./lib/antd";
import http from "./lib/axios";
import common from "./lib/common";

//引入clipboard
import VueClipboard from "vue-clipboard2";
import { Cascader } from "element-ui";
import { Icon } from "ant-design-vue";

import syncLoading from "./lib/syncLoading.js"; // 引入loading
import Directives from "./directives";
import VueDraggableResizable from "vue-draggable-resizable";

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3757162_mu6w2ys8lr.js",
});

Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.component("icon-font", IconFont);
Vue.config.productionTip = false;

let path = location.protocol + process.env.VUE_APP_BASE_API;

if (location.host == "sms.dx53.cn") {
  path = location.protocol + "//smsbackend.dx53.cn/api";
}
Vue.prototype.$path = path;
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.prototype.$common = common;

Vue.use(Cascader);
Vue.use(antd);
Vue.use(Directives);
Vue.use(VueClipboard);
Vue.use(syncLoading); // 全局使用loading

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
