<template>
  <div class="smsStatusInfo">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="通道上行">
        <div class="smsStatusInfo-search">
          <div class="smsStatusInfo-search-1">
            日期:
            <a-range-picker
              v-model="dateString"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              style="width: 240px"
            />
          </div>
          <div class="smsStatusInfo-search-1">
            通道:
            <a-select
              v-model="channelSmsAccountId"
              placeholder="请选择通道"
              allowClear
              style="width: 240px"
              showSearch
              :filterOption="false"
              @search="getChannelSmsAccountIdList"
              @change="inquire(1)"
            >
              <a-select-option
                v-for="i in channelSmsAccountIdList"
                :key="i.id"
                :value="i.id"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="smsStatusInfo-search-1">
            回复内容:
            <a-input
              v-model="msgContent"
              @keyup.enter="inquire(1)"
              placeholder="回复内容"
              allowClear
            />
          </div>
        </div>
        <div class="smsStatusInfo-search-button">
          <a-button type="primary" @click="inquire(1)">查 询</a-button>
        </div>
        <!-- 通道上行 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(signColumns)"
          :columns="signColumns"
          :dataSource="signTableData"
          :pagination="signPagination"
          :loading="loadingTable"
          size="small"
        >
          <span slot="channelName" slot-scope="text, record">
            {{ text + "(" + record.channelAccessKey + ")" }}
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="上行推送详情" force-render>
        <div class="smsStatusInfo-search">
          <div class="smsStatusInfo-search-1">
            accessKey:
            <a-input
              v-model="userAccountAccessKey"
              @keyup.enter="inquirePush(1)"
              placeholder="accessKey"
              allowClear
            />
          </div>
          <div class="smsStatusInfo-search-1">
            客户编号:
            <a-input
              v-model="phoneOrCustomerCode"
              @keyup.enter="inquirePush(1)"
              placeholder="客户编号"
              allowClear
            />
          </div>
          <div class="smsStatusInfo-search-1">
            回复内容:
            <a-input
              v-model="msgContent"
              @keyup.enter="inquirePush(1)"
              placeholder="回复内容"
              allowClear
            />
          </div>
          <div class="smsStatusInfo-search-1">
            日期:
            <a-range-picker
              v-model="dateStringPush"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              style="width: 240px"
            />
          </div>
        </div>
        <div class="smsStatusInfo-search-button">
          <a-button type="primary" @click="inquirePush(1)">查询</a-button>
          <i style="display: inline-block; margin-left: 20px"></i>
          <a-button type="primary" @click="Export()">导出</a-button>
        </div>
        <!-- 通道上行 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(pushColumns)"
          :columns="pushColumns"
          :dataSource="pushTableData"
          :pagination="pushPagination"
          :loading="loadingTable"
          size="small"
        >
          <span slot="province" slot-scope="text, record">
            {{ text + record.city }}
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "aisleUp",
  data() {
    return {
      platTitle: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",

          width: 100,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",

          width: 200,
        },
        {
          title: "通道名",
          ellipsis: true,
          dataIndex: "channelName",
          scopedSlots: { customRender: "channelName" },

          width: 200,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "msgContent",

          width: 100,
        },
        {
          title: "通道号",
          ellipsis: true,
          dataIndex: "channelSmsAccountId",

          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",

          width: 170,
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      currentPagePush: 1,
      dateString: [null, null],
      dateStringPush: [null, null],
      pushColumns: [
        {
          title: "编号",
          align: "center",

          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "任务编号",

          ellipsis: true,
          dataIndex: "planId",
          width: 100,
        },
        {
          title: "通道",

          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          width: 150,
        },
        {
          title: "归属地",

          ellipsis: true,
          dataIndex: "province",
          scopedSlots: { customRender: "province" },
          width: 100,
        },
        {
          title: "用户名",

          ellipsis: true,
          dataIndex: "userLoginName",
          width: 150,
        },
        {
          title: "accessKey",

          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "客户编号",

          ellipsis: true,
          dataIndex: "customerCode",
          width: 150,
        },
        {
          title: "回复内容",

          ellipsis: true,
          dataIndex: "msgContent",
          width: 150,
        },
        {
          title: "创建时间",

          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      pushTableData: [],
      pushPagination: {},
      userAccountAccessKey: "",
      phoneOrCustomerCode: "",
      msgContent: "",
      channelSmsAccountId: undefined,
      channelSmsAccountIdList: [],
    };
  },
  mounted() {
    this.dateString = [
      moment().subtract(6, "days").format("YYYY-MM-DD"),
      moment().subtract(0, "days").format("YYYY-MM-DD"),
    ];
    this.getChannelSmsAccountIdList();
    this.inquire(1);
  },
  methods: {
    getChannelSmsAccountIdList(name) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        name,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result == 200) {
          this.channelSmsAccountIdList = res.data.records;
        }
      });
    },
    callback(key) {
      this.pageSize = 10;
      this.msgContent = "";
      if (key == "1") {
        this.dateString = [
          moment().subtract(6, "days").format("YYYY-MM-DD"),
          moment().subtract(0, "days").format("YYYY-MM-DD"),
        ];
        this.inquire(1);
      }
      if (key == "2") {
        this.dateStringPush = [
          moment().subtract(6, "days").format("YYYY-MM-DD"),
          moment().subtract(0, "days").format("YYYY-MM-DD"),
        ];
        this.inquirePush(1);
      }
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        gmtCreatedStart:
          this.$common.dayFormat(this.dateString[0]) + " 00:00:00",
        gmtCreatedEnd: this.$common.dayFormat(this.dateString[1]) + " 23:59:59",
        msgContent: this.msgContent,
        channelSmsAccountId: this.channelSmsAccountId,
      };
      this.getAisleUp(data);
    },
    // 查询平台列表
    getAisleUp(data) {
      this.loadingTable = true;
      this.$http
        .get("/deliveryReply/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    Export() {
      location.href =
        this.$path +
        `/deliveryReply/exportReportList?pageNo=${
          this.currentPagePush
        }&pageSize=${this.pageSize}&gmtCreatedStart=${
          this.$common.dayFormat(this.dateStringPush[0]) + " 00:00:00"
        }&gmtCreatedEnd=${
          this.$common.dayFormat(this.dateStringPush[1]) + " 23:59:59"
        }&phoneOrCustomerCode=${
          this.phoneOrCustomerCode
        }&userAccountAccessKey=${this.userAccountAccessKey}&msgContent=${
          this.msgContent
        }`;
    },
    // 点击查询
    inquirePush(index) {
      if (index) {
        this.currentPagePush = index;
      }
      let data = {
        pageNo: this.currentPagePush,
        pageSize: this.pageSize,
        gmtCreatedStart:
          this.$common.dayFormat(this.dateStringPush[0]) + " 00:00:00",
        gmtCreatedEnd:
          this.$common.dayFormat(this.dateStringPush[1]) + " 23:59:59",
        phoneOrCustomerCode: this.phoneOrCustomerCode,
        userAccountAccessKey: this.userAccountAccessKey,
        msgContent: this.msgContent,
      };
      this.getPushList(data);
    },
    // 查询推送详情列表
    getPushList(data) {
      this.loadingTable = true;
      this.$http
        .get("/deliveryReply/reportList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.pushTableData = list;
            this.pushPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquirePush(1);
              },
              onChange: (current) => this.currentPage_batch1(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch1(index) {
      this.inquirePush(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 120px !important;
      }
    }

    input {
      width: 120px;
    }

    span {
      width: 120px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
