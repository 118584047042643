<template>
  <div class="platformBlackNumber">
    <div class="platformBlackNumber-search">
      <div class="platformBlackNumber-search-1">
        平台:
        <a-select
          v-model="platformCode"
          placeholder="请选择平台"
          class="select-tag"
          allowClear
          showSearch
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="i in platformList"
            :key="i.code"
            :value="i.code"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="platformBlackNumber-search-1">
        客户编号:
        <a-input
          v-model="customerCode"
          placeholder="请输入customerCode"
          allowClear
        />
      </div>
    </div>
    <div class="platformBlackNumber-search-button">
      <a-button type="primary" @click="inquire()">查询</a-button>
      <i style="display: inline-block; margin-left: 20px"></i>
      <a-button type="primary" @click="cancelBlankNumber()">撤销空号</a-button>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteBlankNumber(record)"
        >
          <template slot="title">是否确认删除{{ record.id }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      title="详情"
      v-model="cancelVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="平台:" required>
          <a-select
            v-model="platformCodeAdd"
            placeholder="请选择平台"
            class="select-tag"
            showSearch
            :filterOption="filterOption"
            @change="getRealStatus"
          >
            <a-select-option
              v-for="i in platformList"
              :key="i.code"
              :value="i.code"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="真实状态:" required>
          <a-select
            v-model="realStatus"
            placeholder="请选择真实状态"
            class="select-tag"
            :disabled="!platformCodeAdd"
          >
            <a-select-option
              v-for="i in realStatusList"
              :key="i.id"
              :value="i.realStatus"
              >{{ i.realStatus }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="cancelVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="cancel_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "platformBlackNumber",
  data() {
    return {
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 200,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "platformName",
          width: 200,
        },
        {
          title: "通道名称",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          width: 200,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          width: 100,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
          width: 80,
        },
        {
          title: "发送时间",
          ellipsis: true,
          dataIndex: "submitTime",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      pagination: {},
      loadingTable: false,
      customerCode: "",
      pageNo: 1,
      realStatus: undefined,
      platformCode: undefined,
      platformCodeAdd: undefined,
      platformList: [],
      cancelVisible: false,
      realStatusList: [],
    };
  },
  mounted() {
    this.getPlatformList();
    this.inquire();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getPlatformList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http.get("/plat/list", data).then((res) => {
        if (res.result === 200) {
          this.platformList.splice(0);
          this.platformList = res.data.records;
        }
      });
    },
    getRealStatus() {
      let data = {
        platformCode: this.platformCodeAdd,
      };
      this.$http
        .get("/blankNumber/platformBlankNumberStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.realStatusList.splice(0);
            this.realStatusList = res.data;
          }
        });
    },
    deleteBlankNumber(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/blankNumber/deleteBlankNumber", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.inquire();
          }
        });
    },
    cancelBlankNumber() {
      this.platformCodeAdd = undefined;
      this.realStatus = undefined;
      this.cancelVisible = true;
    },
    cancel_submit() {
      if (!this.platformCodeAdd || !this.realStatus) {
        this.$message.warning("必填项不可为空");
        return;
      }
      let data = {
        platformCode: this.platformCodeAdd,
        realStatus: this.realStatus,
      };
      this.$http.get("/blankNumber/cancelBlankNumber", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("撤销成功");
          this.cancelVisible = false;
          this.inquire(1);
        }
      });
    },
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        customerCode: this.customerCode,
        platformCode: this.platformCode,
      };
      this.loadingTable = true;
      this.$http
        .get("/blankNumber/blankNumberPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    changePageItem(index) {
      this.inquire(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.platformBlackNumber {
  .platformBlackNumber-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .platformBlackNumber-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .platformBlackNumber-search-button {
    margin-bottom: 20px;
  }
}
</style>
