import { render, staticRenderFns } from "./aisleUp.vue?vue&type=template&id=8248668c&scoped=true"
import script from "./aisleUp.vue?vue&type=script&lang=js"
export * from "./aisleUp.vue?vue&type=script&lang=js"
import style0 from "./aisleUp.vue?vue&type=style&index=0&id=8248668c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8248668c",
  null
  
)

export default component.exports