<template>
  <div class="channelRetryGroup">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input v-model="name" placeholder="请输入名称" allowClear />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <!-- 模拟配置列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green"> 已启用 </a-tag>
        <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="groupClick(record)">分组详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认删除？</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :title="updateTitle"
      v-model="updateShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="启用:">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="updateData.enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="updateSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="groupTitle"
      v-model="groupShow"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchButton">
        <a-button type="primary" @click="addGroupItem">新增</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(groupColumns)"
        :columns="groupColumns"
        :dataSource="groupTableDataSource"
        :pagination="false"
        :loading="loadingGroupTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="enabled" slot-scope="text" style="width: 100%">
          <a-tag v-if="text" color="green"> 已启用 </a-tag>
          <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
        </span>
        <span slot="priorityTitle">
          优先级
          <a-tooltip>
            <template slot="title"> 失败重提会优先选择值更低的通道 </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="viewLog(record)">查看日志</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="detailModifyClick(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteGroupItemClick(record)"
          >
            <template slot="title">确认删除？</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="groupShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="updateGroupItemTitle"
      v-model="updateGroupItemShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="通道:">
          <a-select
            v-model="updateGroupItemData.channelSmsAccountId"
            placeholder="请选择运营商通道"
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
            allowClear
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || !item.connect"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <template v-if="updateGroupItemTitle == '修改'">
          <a-form-item label="优先级:">
            <a-input
              v-model="updateGroupItemData.priority"
              placeholder="请输入优先级"
              allowClear
            />
          </a-form-item>
          <a-form-item label="状态:">
            <a-switch
              checked-children="启用"
              un-checked-children="禁用"
              v-model="updateGroupItemData.enabled"
            />
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateGroupItemShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="updateGroupItemSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-drawer
      title="重提日志"
      placement="right"
      :visible="logVisible"
      @close="logVisible = false"
      width="1000"
    >
      <channel-retry-send-log-page
        :retryGroupItemId="retryGroupItemId"
      ></channel-retry-send-log-page>
    </a-drawer>
  </div>
</template>
<script>
import channelRetrySendLogPage from "./channelRetrySendLogPage.vue";
export default {
  components: { channelRetrySendLogPage },
  name: "channelRetryGroup",
  data() {
    return {
      name: "",
      currentPage: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 200,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      updateTitle: "",
      updateShow: false,
      updateData: {
        name: "",
        enabled: "",
      },
      retryGroupId: "",
      groupTitle: "",
      groupShow: false,
      groupColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "通道名称",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
        },
        {
          ellipsis: true,
          dataIndex: "priority",
          scopedSlots: { title: "priorityTitle" },
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 160,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      groupTableDataSource: [],
      loadingGroupTable: false,
      updateGroupItemTitle: "",
      updateGroupItemShow: false,
      updateGroupItemData: {},
      retryGroupItemId: "",
      searchSmsAccountTimer: null,
      smsAccountList: [],
      logVisible: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        name: this.name,
      };
      this.loadingTable = true;
      this.$http
        .get("/channelRetryGroup/channelRetryGroupPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.keyIndex = index + 1;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.query(1);
              },
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.updateTitle = "新增";
      this.updateData = {
        name: "",
        enabled: true,
      };
      this.updateShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.retryGroupId = val.id;
      this.updateTitle = "修改";
      this.updateData = {
        name: val.name,
        enabled: val.enabled,
      };
      this.updateShow = true;
    },
    updateSubmit() {
      let data = { ...this.updateData };
      if (this.updateTitle === "新增") {
        this.$http
          .json_post("/channelRetryGroup/addChannelRetryGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.updateShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          });
      } else if (this.updateTitle === "修改") {
        data.id = this.retryGroupId;
        this.$http
          .json_post("/channelRetryGroup/editChannelRetryGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.updateShow = false;
              this.$message.success("修改成功");
              this.query(1);
            }
          });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/channelRetryGroup/deleteChannelRetryGroup", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    // 点击详情
    groupClick(val) {
      this.retryGroupId = val.id;
      this.groupShow = true;
      this.groupTitle = "【" + val.name + "】分组详情";
      this.getSmsAccountList();
      this.getChannelRetryGroupItemList();
    },
    getChannelRetryGroupItemList() {
      this.loadingGroupTable = true;
      let data = {
        retryGroupId: this.retryGroupId,
      };
      this.$http
        .get("/channelRetryGroup/listChannelRetryGroupItem", data)
        .then((res) => {
          this.loadingGroupTable = false;
          if (res.result === 200) {
            let list = res.data;
            this.groupTableDataSource = list;
          }
        })
        .catch((err) => {
          this.loadingGroupTable = false;
        });
    },
    addGroupItem() {
      this.updateGroupItemTitle = "新增";
      this.updateGroupItemData = {
        channelSmsAccountId: undefined,
      };
      this.updateGroupItemShow = true;
    },
    viewLog(val) {
      this.retryGroupItemId = val.id;
      this.logVisible = true;
    },
    // 点击修改
    detailModifyClick(val) {
      this.updateGroupItemTitle = "修改";
      this.updateGroupItemData = {
        channelSmsAccountId: val.channelSmsAccountId,
        enabled: val.enabled,
        priority: val.priority,
      };
      this.retryGroupItemId = val.id;
      this.updateGroupItemShow = true;
    },
    searchSmsAccountList(accessKeyOrName) {
      if (this.searchSmsAccountTimer) {
        clearTimeout(this.searchSmsAccountTimer);
        this.searchSmsAccountTimer = null;
      }
      this.searchSmsAccountTimer = setTimeout(() => {
        this.getSmsAccountList(accessKeyOrName);
      }, 300);
    },
    getSmsAccountList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        accessKeyOrName,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.smsAccountList = res.data.records;
        }
      });
    },
    updateGroupItemSubmit() {
      let data = {
        retryGroupId: this.retryGroupId,
        ...this.updateGroupItemData,
      };
      if (this.updateGroupItemTitle === "新增") {
        this.$http
          .json_post("/channelRetryGroup/addChannelRetryGroupItem", data)
          .then((res) => {
            if (res.result === 200) {
              this.updateGroupItemShow = false;
              this.$message.success("新增成功");
              this.getChannelRetryGroupItemList();
            }
          });
      } else if (this.updateGroupItemTitle === "修改") {
        data.id = this.retryGroupItemId;
        this.$http
          .json_post("/channelRetryGroup/editChannelRetryGroupItem", data)
          .then((res) => {
            if (res.result === 200) {
              this.updateGroupItemShow = false;
              this.$message.success("修改成功");
              this.getChannelRetryGroupItemList();
            }
          });
      }
    },
    deleteGroupItemClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/channelRetryGroup/deleteChannelRetryGroupItem", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.getChannelRetryGroupItemList();
          }
        });
    },
  },
};
</script>
