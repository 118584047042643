<template>
  <div class="smsContent">
    <div class="content-item">
      <a-textarea
        v-model="SMStextarea"
        placeholder="请输入短信内容"
        :rows="8"
        @change="inputChange"
        :disabled="isDisabled"
        id="shortContent"
        style="width: 99%"
      />
    </div>
    <div class="word">
      <div class="sign-c">
        <a
          href="javascript:void(0)"
          :disabled="isNewEndNumDis"
          @click="endNumberChain('#新尾号#')"
          >#新尾号#</a
        >
        <a
          href="javascript:void(0)"
          :disabled="isEndNumDis"
          @click="endNumberChain('#尾号#')"
          >#尾号#</a
        >
        <a
          href="javascript:void(0)"
          :disabled="isShortDis"
          @click="endNumberChain('#短链#')"
          v-if="shortUrlShow"
          >#短链#</a
        >
        <a
          href="javascript:void(0)"
          :disabled="isShortCodeDis"
          @click="endNumberChain('#短编码#')"
          v-if="shortUrlShow"
          >#短编码#</a
        >
        <a
          href="javascript:void(0)"
          :disabled="isCityDis"
          @click="endNumberChain('#城市#')"
          >#城市#</a
        >
        <a
          href="javascript:void(0)"
          :disabled="isDateDis"
          @click="endNumberChain('#日期#')"
          >#日期#</a
        >
      </div>
      <div>
        已输入
        <i>{{ SMS.totalNum }}</i
        >个字： 签名 <i>({{ SMS.signLength }})</i>+ 内容(
        <i>{{ SMS.contentNum }}</i
        >) ，共消耗 <i>{{ SMS.num }}</i
        >条短信
      </div>
    </div>
    <div class="sms-info-style" v-if="prefixValue">
      <span v-if="isShortDis"
        >前缀：
        <a-radio-group v-model="prefix" style="margin-left: 28px">
          <a-radio value="https://">https://</a-radio>
          <a-radio value="none">none</a-radio>
        </a-radio-group>
      </span>
    </div>
    <div class="sms-info-style" v-if="domain">
      <span v-if="isShortDis" class="info-required"
        >域名：
        <a-select
          v-model="selectDomain"
          placeholder="请选择域名"
          @change="selectDomainChange(selectDomain)"
          style="width: 300px; margin-left: 28px"
        >
          <a-select-option
            v-for="i in selectDomainList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </span>
    </div>
    <div class="sms-info-style" v-if="longUrlValue && !longUrlGroupIdValue">
      <span v-if="isShortDis" class="info-required"
        >长链：
        <a-input
          v-model="longUrl"
          placeholder="请输入长链"
          @input="longUrlChange"
          @blur="compareToLongUrl(longUrl)"
          style="width: 300px; margin-left: 28px"
        />
        <div
          v-if="urlFormatDisabel"
          class="dropbox-ts"
          style="
            color: red;
            font-size: 12px;
            margin-left: 76px;
            line-height: 20px;
          "
        >
          长网址链接格式不正确
        </div>
      </span>
    </div>
    <div class="sms-info-style" v-if="longUrlGroupIdValue">
      <span v-if="isShortDis" class="info-required">
        长链分组：
        <a-select
          v-model="longUrlGroupId"
          placeholder="请选择长链分组"
          showSearch
          :filterOption="false"
          @search="getLongUrlGroupList"
          @change="longUrlGroupIdChange"
          style="width: 300px"
        >
          <a-select-option
            v-for="i in longUrlGroupList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </span>
    </div>
    <div v-if="urlTitleShow">
      <span v-if="isShortDis"
        >长链名称：
        <a-input
          v-model="urlTitle"
          placeholder="请输入长链名称"
          @input="urlTitleChange"
          style="width: 300px"
        />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsInput",
  props: {
    textareaSMS: {
      // 短信内容
      type: String,
      default: "",
    },
    longUrlValue: {
      // 长链
      type: String,
      default: "",
    },
    longUrlGroupIdValue: {
      // 长链
      type: Number,
      default: undefined,
    },
    urlTitleValue: {
      // 长链名称
      type: String,
      default: "",
    },
    prefixValue: {
      // 前缀
      type: String,
      default: "",
    },
    sign: {
      // 签名
      type: String,
      default: "",
    },
    isDisabled: {
      // 禁用
      type: Boolean,
      default: false,
    },
    urlFormat: {
      // 长链格式提醒
      type: Boolean,
      default: false,
    },
    urlTitleShow: {
      // 长链名称显示
      type: Boolean,
      default: false,
    },
    shortUrlShow: {
      // 短链按钮显示
      type: Boolean,
      default: true,
    },
    domain: {
      // 域名list
      type: String,
      default: "",
    },
    maxUrlLength: {
      // 短链长度
      type: Number,
      default: 13,
    },
    selectDomainList: {
      // 域名list
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      urlFormatDisabel: false,
      SMStextarea: "",
      isNewEndNumDis: false,
      isEndNumDis: false,
      isShortDis: false,
      isShortCodeDis: false,
      isCityDis: false,
      isDateDis: false,
      prefix: "https://",
      longUrl: "",
      urlTitle: "",
      selectDomain: undefined,
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        num: 0,
      },
      longUrlGroupList: [],
      longUrlGroupId: undefined,
    };
  },
  computed: {
    SMStextareaLabel() {
      return this.sign + this.SMStextarea;
    },
  },
  watch: {
    sign(val) {
      this.smsTotal();
    },
    SMStextarea(val) {
      this.smsTotal();
    },
    textareaSMS(val) {
      // 短信内容
      if (val) {
        this.SMStextarea = val;
      } else {
        this.SMStextarea = "";
      }
      this.$emit("change", val);
    },
    // 监听前缀
    prefix(val) {
      this.smsTotal();
      this.$emit("prefixChange", val);
    },
    prefixValue(val) {
      if (val) {
        this.prefix = val;
      } else {
        this.prefix = "none";
      }
    },
    longUrlGroupIdValue(val) {
      if (val) {
        this.longUrlGroupId = val;
      } else {
        this.longUrlGroupId = undefined;
      }
    },
    longUrlValue(val) {
      if (val) {
        this.longUrl = val;
      } else {
        this.longUrl = "";
      }
    },
    domain(val) {
      if (val) {
        this.selectDomain = val;
      } else {
        this.selectDomain = undefined;
      }
      this.$emit("selectDomainChange", val);
    },
    urlTitleValue(val) {
      if (val) {
        this.urlTitle = val;
      } else {
        this.urlTitle = "";
      }
      this.$emit("urlTitleChange", val);
    },
    urlFormat(val) {
      this.urlFormatDisabel = val;
    },
  },
  created() {
    this.getLongUrlGroupList();
  },
  methods: {
    getLongUrlGroupList(name) {
      this.$http.get("/longUrlGroup/longUrlGroupList", { name }).then((res) => {
        if (res.result === 200) {
          this.longUrlGroupList = res.data;
        }
      });
    },
    // 短信输入
    inputChange(val) {
      this.$emit("change", this.SMStextarea);
    },
    // 域名选择
    selectDomainChange(val) {
      this.$emit("selectDomainChange", this.selectDomain);
    },
    // 长链输入
    longUrlChange(val) {
      this.$emit("longUrlChange", this.longUrl);
    },
    longUrlGroupIdChange(val) {
      this.$emit("longUrlGroupIdChange", this.longUrlGroupId);
    },
    // 长链名称输入
    urlTitleChange(val) {
      this.$emit("urlTitleChange", this.urlTitle);
    },
    // 提示长链接格式是否正确
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.$emit("urlFormatChange", false);
          return;
        }
        this.$emit("urlFormatChange", true);
      } else {
        this.$emit("urlFormatChange", true);
      }
    },
    // 插入尾号 点击尾号---挂机短信设置
    endNumberChain(text) {
      this.SMStextarea = this.$common.insertInputTxt(
        text,
        "shortContent",
        this.SMStextarea
      );
      this.$emit("change", this.SMStextarea);
    },
    // 计算短信字数
    smsTotal() {
      //文本域
      let SMStextarea = this.SMStextareaLabel;
      let index = SMStextarea.indexOf("#短链#");
      let endNum = SMStextarea.indexOf("#尾号#");
      let NewEndNum = SMStextarea.indexOf("#新尾号#");
      let shortCodeIndex = SMStextarea.indexOf("#短编码#");
      let cityIndex = SMStextarea.indexOf("#城市#");
      let dateIndex = SMStextarea.indexOf("#日期#");

      let signFirstIndex = SMStextarea.indexOf("【");
      let signLastIndex = SMStextarea.indexOf("】");
      this.SMS.signLength = 0;
      if (signFirstIndex !== -1 && signLastIndex !== -1) {
        this.SMS.signLength = signLastIndex - signFirstIndex + 1; // 标签长度计算
        let startText = SMStextarea.substring(0, signFirstIndex);
        let endText = SMStextarea.substring(signLastIndex + 1);
        SMStextarea = startText + endText;
      }
      if (index !== -1 && this.shortUrlShow) {
        //有短信内容 #短链#
        if (this.prefix === "https://") {
          this.SMS.contentNum = SMStextarea.length + this.maxUrlLength + 8 - 4; // 前缀https:// 21
        } else {
          this.SMS.contentNum = SMStextarea.length + this.maxUrlLength - 4; // 前缀无 13
        }
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isShortDis = true;
        if (cityIndex !== -1) {
          this.SMS.contentNum = this.SMS.contentNum - 2; // 城市算2个字符
          this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
          this.isCityDis = true;
        } else {
          this.isCityDis = false;
        }
      } else {
        this.SMS.contentNum = SMStextarea.length;
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isShortDis = false;
        if (cityIndex !== -1) {
          this.SMS.contentNum = SMStextarea.length - 2; // 城市算2个字符
          this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
          this.isCityDis = true;
        } else {
          this.isCityDis = false;
        }
      }
      if (dateIndex !== -1) {
        this.isDateDis = true;
      } else {
        this.isDateDis = false;
      }
      if (endNum !== -1) {
        this.isEndNumDis = true;
      } else {
        this.isEndNumDis = false;
      }
      if (NewEndNum !== -1) {
        this.isNewEndNumDis = true;
      } else {
        this.isNewEndNumDis = false;
      }
      if (shortCodeIndex !== -1) {
        this.isShortCodeDis = true;
      } else {
        this.isShortCodeDis = false;
      }
      if (this.SMS.totalNum < 71) {
        this.SMS.num = 1;
      } else {
        this.SMS.num = Math.ceil((this.SMS.totalNum - 70) / 67) + 1;
      }
      this.$forceUpdate();
      this.$emit("smsNum", this.SMS.num);
    },
  },
};
</script>
<style scoped>
.content-item {
  display: flex;
  justify-content: space-between;
}
.sms-info-style {
  margin-bottom: 10px;
}
.info-required::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.smsContent {
  width: 100%;
  margin: 10px 0;
  position: relative;
}
.smsContent .template {
  float: right;
  font-size: 14px;
  margin-bottom: 5px;
  color: #3a8ee6;
}
.word {
  width: 99%;
  line-height: 40px;
  text-align: right;
  font-style: normal;
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.word .template-sign {
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.sign-c a {
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.sign-c a {
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 0px;
}
.sign-c a {
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.word i {
  color: red;
  font-style: normal;
}
</style>
