<template>
  <div class="userReportList">
    <div class="userReportList-search">
      <div class="userReportList-search-1">
        accessKey:
        <a-input
          v-model="userAccountAccessKey"
          @keyup.enter="inquire(1)"
          placeholder="accessKey"
          allowClear
        />
      </div>
      <div class="userReportList-search-1">
        客户编号:
        <a-input
          v-model="phoneOrCustomerCode"
          @keyup.enter="inquire(1)"
          placeholder="客户编号"
          allowClear
        />
      </div>
      <div class="userReportList-search-1">
        回复内容:
        <a-input
          v-model="msgContent"
          @keyup.enter="inquire(1)"
          placeholder="回复内容"
          allowClear
        />
      </div>
      <div class="userReportList-search-1">
        日期:
        <a-range-picker
          v-model="dateStringPush"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="userReportList-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <i style="display: inline-block; margin-left: 20px"></i>
      <a-button type="primary" @click="Export()">导出</a-button>
    </div>
    <!-- 通道上行 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(pushColumns)"
      :columns="pushColumns"
      :dataSource="pushTableData"
      :pagination="pushPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="province" slot-scope="text, record">
        {{ text + record.city }}
      </span>
    </a-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "userReportList",
  data() {
    return {
      userAccountAccessKey: "",
      phoneOrCustomerCode: "",
      msgContent: "",
      dateStringPush: [
        moment().subtract(6, "days").format("YYYY/MM/DD"),
        moment().subtract(0, "days").format("YYYY/MM/DD"),
      ],
      pushColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "任务编号",
          ellipsis: true,
          dataIndex: "planId",
          width: 100,
        },
        {
          title: "归属地",
          ellipsis: true,
          dataIndex: "province",
          scopedSlots: { customRender: "province" },
          width: 150,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 120,
        },
        {
          title: "回复内容",
          ellipsis: true,
          dataIndex: "msgContent",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      pushTableData: [],
      pushPagination: {},
      loadingTable: false,
      pageSize: 10,
      currentPagePush: 1,
    };
  },
  created() {
    this.inquire();
  },
  methods: {
    Export() {
      location.href =
        this.$path +
        `/deliveryReply/exportUserReportList?pageNo=${
          this.currentPagePush
        }&pageSize=${this.pageSize}&gmtCreatedStart=${
          this.$common.dayFormat(this.dateStringPush[0]) + " 00:00:00"
        }&gmtCreatedEnd=${
          this.$common.dayFormat(this.dateStringPush[1]) + " 23:59:59"
        }&phoneOrCustomerCode=${
          this.phoneOrCustomerCode
        }&userAccountAccessKey=${this.userAccountAccessKey}&msgContent=${
          this.msgContent
        }`;
    },
    inquire(index) {
      if (index) {
        this.currentPagePush = index;
      }
      let data = {
        pageNo: this.currentPagePush,
        pageSize: this.pageSize,
        gmtCreatedStart:
          this.$common.dayFormat(this.dateStringPush[0]) + " 00:00:00",
        gmtCreatedEnd:
          this.$common.dayFormat(this.dateStringPush[1]) + " 23:59:59",
        phoneOrCustomerCode: this.phoneOrCustomerCode,
        userAccountAccessKey: this.userAccountAccessKey,
        msgContent: this.msgContent,
      };
      this.getPushList(data);
    },
    // 查询推送详情列表
    getPushList(data) {
      this.loadingTable = true;
      this.$http
        .get("/deliveryReply/userReportList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.pushTableData = list;
            this.pushPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage(index) {
      this.inquire(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.userReportList {
  .userReportList-search {
    position: relative;
    margin-bottom: 20px;
    .userReportList-search-1 {
      display: inline-block;
      margin-right: 20px;

      .select-tag {
        width: 120px !important;
      }
    }

    input {
      width: 120px;
    }

    span {
      width: 120px;
    }
  }
  .userReportList-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
