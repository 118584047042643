<template>
  <div class="channelSmsGroupAccount">
    <div class="management-search">
      <div class="management-search-1">
        组合名称：
        <a-input
          placeholder="请输入组合名称"
          v-model="name"
          @keyup.enter="query(1)"
          allowClear
        />
      </div>
    </div>
    <div class="projectList-search-button">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <i style="margin-left: 20px"></i>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span
        slot="name"
        slot-scope="text, record"
        style="width: 100%"
        class="slotCell"
      >
        <a href="javascript:;" class="content" @click="detail(record)">
          {{ text }}
        </a>
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          v-model="text"
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          @change="enabledChange($event, record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="组合名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入组合名称" />
        </a-form-item>
        <a-form-item
          label="启用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title == '新增'"
        >
          <a-switch
            v-model="enabledAdd"
            checkedChildren="已启用"
            unCheckedChildren="已禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="detailTitle"
      v-model="detailVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <div style="margin-bottom: 10px">
        <a-button type="primary" @click="detail()">查询</a-button>
        <i style="margin-left: 20px"></i>
        <a-button type="primary" @click="addDetail">新增</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(detailTableColumns)"
        :columns="detailTableColumns"
        :dataSource="detailTableDataSource"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span
          slot="enabled"
          slot-scope="text, record, index"
          style="width: 100%"
          v-if="record.show"
        >
          <a-switch
            v-model="text"
            checkedChildren="已启用"
            unCheckedChildren="已禁用"
            @change="detailEnabledChange($event, record)"
          />
        </span>
        <span
          slot="enabled"
          slot-scope="text, record, index"
          style="width: 100%"
          :colSpan="0"
          v-else
        >
        </span>
        <span slot="action" slot-scope="text, record" v-if="record.show">
          <a href="javascript:;" @click="detailModifyClick(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="detailDeleteClick(record)"
          >
            <template slot="title">是否确认删除{{ record.name }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="detailEditTitle"
      v-model="detailEditVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="通道账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="channelSmsAccountIdAdd"
            placeholder="请选择通道账号"
            class="select-tag"
            allowClear
            :filterOption="false"
            @search="getSmsAccountList"
            showSearch
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            权重
            <a-tooltip placement="topLeft">
              <template slot="title">权重越高,命中率越高</template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="weightAdd" placeholder="请输入权重" />
        </a-form-item>
        <a-form-item
          label="排除区域"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <el-cascader
            v-model="forbiddenArea"
            :options="options"
            :props="props"
            filterable
            :show-all-levels="false"
            clearable
            collapse-tags
            size="small"
            style="width: 160px"
          ></el-cascader>
        </a-form-item>
        <a-form-item
          label="启用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="detailEditTitle == '新增'"
        >
          <a-switch
            v-model="enabledAdd"
            checkedChildren="已启用"
            unCheckedChildren="已禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailEditVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="detail_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "channelSmsGroupAccount",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      enabledAdd: true,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "组合名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 350,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 120,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      detailTitle: "",
      detailVisible: false,
      detailTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          customRender: (text, row, index) => {
            if (index == this.detailTableDataSource.length - 1) {
              return {
                children: <span>总权重</span>,
                attrs: {
                  colSpan: 5,
                },
              };
            }
            return {
              children: <span>{text}</span>,
            };
          },
          align: "center",
          width: 60,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "channelSmsAccountAccessKey",
          customRender: (text, row, index) => {
            if (index == this.detailTableDataSource.length - 1) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: <span>{text}</span>,
            };
          },
          width: 90,
        },
        {
          title: "通道账号名称",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          customRender: (text, row, index) => {
            if (index == this.detailTableDataSource.length - 1) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: <span>{text}</span>,
            };
          },
          width: 170,
        },
        {
          title: "排除区域",
          ellipsis: true,
          dataIndex: "forbiddenArea",
          customRender: (text, row, index) => {
            if (index == this.detailTableDataSource.length - 1) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: <span>{text}</span>,
            };
          },
          width: 120,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          customRender: (text, row, index) => {
            if (index == this.detailTableDataSource.length - 1) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return {
              children: (
                <a-switch
                  v-model={text}
                  checkedChildren="已启用"
                  unCheckedChildren="已禁用"
                  onChange={() => this.detailEnabledChange(text, row)}
                />
              ),
            };
          },
          width: 90,
        },
        {
          title: "权重",
          ellipsis: true,
          dataIndex: "weight",
          width: 50,
        },
        {
          title: "命中率",
          ellipsis: true,
          dataIndex: "rate",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      detailTableDataSource: [],
      detailEditVisible: false,
      detailEditTitle: "",
      weightAdd: "",
      detailId: "",
      smsAccountList: [],
      channelSmsAccountIdAdd: "",
      weight: "",
      props: { multiple: true, emitPath: false, checkStrictly: true },
      options: [],
      forbiddenArea: [],
    };
  },
  mounted() {
    this.query(1);
    this.getSmsAccountList();
    this.getProvinceAndCity();
  },
  methods: {
    getProvinceAndCity() {
      this.$http.get("/userPlatformRelation/getProvinceAndCity").then((res) => {
        if (res.result === 200) {
          this.options.splice(0);
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.value = i;
            a.label = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.value = i + ":" + j;
                b.label = i + " " + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    getSmsAccountList(name) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        name,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.smsAccountList = res.data.records;
        }
      });
    },
    detail(val) {
      if (val) {
        this.groupId = val.id;
        this.detailTitle = val.name + "详情";
      }
      let data = {
        groupId: this.groupId,
      };
      this.$http
        .get("/channelSmsGroupAccountItem/channelSmsGroupAccountItem", data)
        .then((res) => {
          if (res.result === 200) {
            this.detailTableDataSource.splice(0);
            this.detailTableDataSource.push(...res.data);
            let weight = 0;
            this.detailTableDataSource.forEach((element) => {
              weight += element.weight;
            });
            this.weight = weight;
            this.detailTableDataSource.forEach((element) => {
              this.$set(
                element,
                "rate",
                ((element.weight / weight) * 100).toFixed(2) + "%"
              );
              this.$set(element, "show", true);
            });
            this.detailTableDataSource.push({
              id: -1,
              show: false,
              weight,
            });
            this.detailVisible = true;
          }
        });
    },
    detailEnabledChange(enabled, val) {
      this.$http
        .get("/channelSmsGroupAccountItem/updateEnabled", {
          id: val.id,
          enabled,
        })
        .then((res) => {
          if (res.result == 200) {
            if (enabled) {
              this.$message.success("启用成功");
            } else {
              this.$message.success("禁用成功");
            }
            this.detail();
          }
        });
    },
    enabledChange(enabled, val) {
      this.$http
        .get("/channelSmsGroupAccount/updateEnabled", { id: val.id, enabled })
        .then((res) => {
          if (res.result == 200) {
            if (enabled) {
              this.$message.success("启用成功");
            } else {
              this.$message.success("禁用成功");
            }
            this.query();
          }
        });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      this.$http
        .get("/channelSmsGroupAccount/channelSmsGroupAccountPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.query(1);
              },
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.nameAdd = val.name;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.enabledAdd = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
      };
      if (this.title === "新增") {
        data.enabled = this.enabledAdd;
        this.$http
          .json_post("/channelSmsGroupAccount/addChannelSmsGroupAccount", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.groupId;
        this.$http
          .json_post("/channelSmsGroupAccount/editChannelSmsGroupAccount", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("修改成功");
              this.query();
            }
          });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.nameAdd = "";
      this.enabledAdd = true;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/channelSmsGroupAccount/deleteChannelSmsGroupAccount", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    addDetail() {
      this.detailEditTitle = "新增";
      this.enabledAdd = true;
      this.weightAdd = 100;
      this.channelSmsAccountIdAdd = undefined;
      this.forbiddenArea = [];
      this.detailEditVisible = true;
    },
    detailModifyClick(val) {
      this.detailEditTitle = "修改";
      this.detailId = val.id;
      this.enabledAdd = val.enabled;
      this.weightAdd = val.weight;
      this.channelSmsAccountIdAdd = val.channelSmsAccountId;
      this.forbiddenArea = val.forbiddenArea.split(",");
      this.detailEditVisible = true;
    },
    detail_submit() {
      let data = {
        weight: this.weightAdd,
        channelSmsAccountId: this.channelSmsAccountIdAdd,
        groupId: this.groupId,
        forbiddenArea: this.forbiddenArea.join(","),
      };
      if (this.detailEditTitle === "新增") {
        data.enabled = this.enabledAdd;
        this.$http
          .json_post(
            "/channelSmsGroupAccountItem/addChannelSmsGroupAccountItem",
            data
          )
          .then((res) => {
            if (res.result === 200) {
              this.detailEditVisible = false;
              this.$message.success("添加成功");
              this.detail();
            }
          });
      } else {
        data.id = this.detailId;
        this.$http
          .json_post(
            "/channelSmsGroupAccountItem/editChannelSmsGroupAccountItem",
            data
          )
          .then((res) => {
            if (res.result === 200) {
              this.detailEditVisible = false;
              this.$message.success("修改成功");
              this.detail();
            }
          });
      }
    },
    detailDeleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post(
          "/channelSmsGroupAccountItem/deleteChannelSmsGroupAccountItem",
          data
        )
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.detail();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.channelSmsGroupAccount {
  .management-search {
    position: relative;
    overflow: hidden;
    .management-search-1 {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
    }
    .backup {
      padding: 5px 0;
    }
    span {
      width: 240px;
    }
    input {
      width: 240px;
    }
    .ant-select {
      width: 240px;
    }
  }
  .projectList-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
    .request_nav_item {
      display: inline-block;
      margin-right: 20px;
      width: 350px;
      input {
        width: 250px;
      }
      .ant-select {
        width: 250px;
      }
    }
  }
}
</style>
